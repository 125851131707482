import { createGlobalStyle } from 'styled-components'
import 'bootstrap/dist/css/bootstrap.css'

export const GlobalStyle = createGlobalStyle`
  html {
    height: -webkit-fill-available;
  }
  body {
      min-height: 100vh;
      min-height: -webkit-fill-available;
      width: 100%;
      font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
      display: flex;
      flex-direction: column;s
  }
  .borderBottomDiv{
    box-shadow: inset 0 -1px 0 var(--grey100-color);
  }
  #root {
    height: var(--height);
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  :root {
    --height: auto;
    --primary050-color: ${props => props.theme.palette.primary050};
    --primary500-color: ${props => props.theme.palette.primary500};
    --primary300-color: ${props => props.theme.palette.primary300};
    --primary100-color: ${props => props.theme.palette.primary100};
    --grey050-color: ${props => props.theme.palette.grey050};
    --grey100-color: ${props => props.theme.palette.grey100};
    --grey200-color: ${props => props.theme.palette.grey200};
    --grey300-color: ${props => props.theme.palette.grey300};
    --grey500-color: ${props => props.theme.palette.grey500};
    --grey800-color: ${props => props.theme.palette.grey800};
    --green900-color: ${props => props.theme.palette.green900};
    --red900-color: ${props => props.theme.palette.red900};
    --semanticRed100-color: ${props => props.theme.palette.semanticRed100};
    --semanticRed900-color: ${props => props.theme.palette.semanticRed900};
    --semanticGreen100-color: ${props => props.theme.palette.semanticGreen100};
    --semanticGreen500-color: ${props => props.theme.palette.semanticGreen500};
    --semanticGreen900-color: ${props => props.theme.palette.semanticGreen900};
    --semanticRed900-color: ${props => props.theme.palette.semanticRed900};
    --white-color: ${props => props.theme.palette.white};
    --black100-color: ${props => props.theme.palette.black100};
    --white010-color: ${props => props.theme.palette.white010};
  }
  b, strong {
    font-weight: 600;
  }
  .dFlex{
    display: flex;
  }
  .alignCenter{
    align-items: center;
  }
  .noPad {
    padding:0;
  }
  .main-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    
    .container-fluid { 
      min-height: 100%;
      height:100% ;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  span.error {
    color: var(--semanticRed900-color);
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    svg {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
  }
  .disable-select {
    user-select: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
  }
  .ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .error {
    font-size: 12px;
    line-height: 20px;
    color: var(--semanticRed900-color);
  }
  .helptext {
    font-size: 12px;
    line-height: 16px;
    color: var(--grey500-color);
    display: inline-block;
    vertical-align: top;
  }
  label {
    color: var(--grey500-color);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .customModalInfo{
    .modal-content{
        color: var(--grey500-color);
        padding: 32px;
        .modal-header{
          padding: 0;
          padding-bottom: 15px;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          border: 0;
          button{
            display: none;
          }
        }
        .modal-body{
          padding: 0;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          p {
            margin-bottom: 5px;
            margin-top: 20px;
            font-weight: 600;
          }
        }
        .modal-footer{
          padding: 0;
          padding-top: 30px;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: var(--primary500-color);
          border: 0 ;
          justify-content: center;
          button {
            color: var(--primary500-color);
            font-weight: 500;
            background-color: transparent;
            border-color: transparent;
          }
        }
      }
    }
    .refineQuotesModal {
        .modal-content {
            .modal-footer {

                padding-top: inherit;

                button.btn-primary {
                  color: var(--white-color);
                  border: 2px solid var(--primary500-color);
                  background: var(--primary500-color);
                  max-width: 120px;
                  width: 100%;
                  border-radius: 8px;
                  height: 48px;
                  font-size: 14px;
                }
            }
        }

        .form-group {
            margin-bottom:0;
            height: inherit;
        }
    }
  .greenColor {
    color: var(--semanticGreen500-color) !important;
  }
  .redColor {
    color: var(--primary500-color) !important;
  }
  .headerTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--grey500-color);
    text-align: center;
    margin-bottom: 0;
    width: calc(100% - 57px);
  }
  .form-group {
    .error {
      &.radioError {
        color: var(--semanticRed900-color) !important;
        font-size: 12px;
        margin-bottom: 0;
        margin-top: -5px;
        display: flex;
      }
    }
  }
  .can-toggle.toggleBtn input[type='checkbox']:checked:focus ~ label .can-toggle__switch:after,
  .can-toggle.toggleBtn input[type='checkbox']:checked:hover ~ label .can-toggle__switch:after,
  .can-toggle input[type='checkbox']:focus ~ label .can-toggle__switch:after,
  .can-toggle input[type='checkbox']:hover ~ label .can-toggle__switch:after {
    box-shadow: none !important;
  }
  .desktopView {
    margin: 0 auto;
    width: 100%;
    .sticky {
      max-width: 576px;
      margin: 0 auto;
      box-shadow: 0 4px 4px -4px rgba(0,0,0,0.0925754);
    }
  }
  .mbt-0 {
    margin-bottom: 0 !important;
  }
  .mt-24 {
    margin-top: 24px;
  }
  .mbt-16 {
    margin-bottom: 16px;
  }
  .desktopLayout {
    // min-height:100%;
    max-width: 576px;
    margin: 0 auto;
    margin-bottom: 56px;
    width: 100%;
    box-shadow: 0 4px 30px rgb(129 139 195 / 19%);
    position: relative;
    border: 1px solid var(--grey100-color);
    border-top: 0;
    border-radius: 0 0 16px 16px;
    @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
    .stepper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      background: var(--white-color);
      box-shadow: 0 2px 4px var(--grey100-color), inset 0 -1px 0 var(--grey100-color);
      padding: 4px 16px 13px 16px;
      position: fixed;
      top: 0;
      z-index:1;
      width: 574px;
      @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
        height: 61px;
      }
      & > div {
        width: 30%;
        padding-right: 16px;
        @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
          padding-right: 4px;
        }
      }      
      @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
        width: 100%;
      }
    }
    .stepContainer {
      min-height: 600px;
      overflow-y: auto;
      padding: 80px 24px 96px 24px;
      @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
        padding: 61px 24px 96px 24px;
        min-height: auto;
        overflow-y: auto;
      }
      h1 {
        margin: 24px 0 16px 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: var(--grey500-color);
      }
      .layoutSubHeading {
        color: var(--grey300-color);
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
      }
      .postcode-step {
        .stepInnerSection {
          padding: 24px;
        }
      }
      &.quotesContainer {
        padding: 140px 16px 30px;

        a.quotesCardLink {
          &:hover {
            text-decoration: none;
          }
          &:focus {
            outline: none;
            > div {
              border: 1px solid var(--grey300-color);
            }
          }
          &[disabled] {
            pointer-events: none;
            .cardInner,
            .borderLine,
            .quotesBottomRow {
              opacity: 0.4;              
            }            
          }
        }

      }
      .quotesButtonWrapper {
        text-align: center;
        margin-top: 4px;
      }

      &.introStep {
        max-width: 300px;
        margin: auto;
        padding: 48px 0 96px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;


        form {
          max-width: 345px;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }

        h1{
          margin-bottom: 0;
          margin-top: 40px;
          text-align: center;
        }
      }
      .supplierConsentWrapper {
        .form-group {          
          margin-top: 0;
          margin-bottom: 56px;
          position: relative;

          .checkboxOption {
            width: calc((100% - 28px) / 4);
            margin: 4px 4px 0 4px;

            &:not(.checkboxOption + .checkboxOption)  {
              margin-left: 0;
            }

            &:nth-child(3) {
              margin-right: 4px;
            }            
          }
          .errorMainWrap {
            position: absolute;
            margin-bottom: 0;
            bottom: -28px;
            width: 100%;
            left: 0;
            .error {
              margin-top: 12px;
            }
          }
        }
      }
      &.quoteDetailsContainer {
        padding: 0 0 120px 0;

        h1 {
          padding: 0;
          margin-top: 0;
        }

        .messageBox {
          margin: 65px 18px 16px 18px;
        }

        .detailSummary p {
          padding-bottom: 0;
        }

        .form-group {
          height: auto;
          margin: 0;
        }
        .backWrapper {
          height: auto ;
        }
      }
      .supplierPreferencesMain {
        margin-bottom: 52px;
        .form-group {
          position: relative;
          .errorMainWrap {
            position: absolute;
            bottom: -24px;
            left: 0;
          }
        }
      }
    }       
  }
  .svgStrokeColor {
    path {
      stroke: var(--primary500-color);
    }
  } 
  .svgFillColor {
    path {
      fill: var(--primary500-color);
    }
  }
  .footer-container {
    padding: 23px 24px 24px;
    border-top: 1px solid var(--grey100-color);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -2px 4px rgba(69, 86, 130, 0.1);
    background: var(--white-color);
    border-radius: 0 0 16px 16px;
    max-width: 576px;
    margin: 0 auto;
    margin-bottom: 0 !important;
    z-index: 9;
    &.status-bottom {
      text-align: center;
      padding-bottom: 12px;
      .status-message{
        color: var(--grey300-color);
        font-weight: 600;
        .timerStart{
          margin-bottom: 0;
          padding-top: 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin-right: 5px;
          }
        } 
        &:hover,&:focus{
          color: var(--grey300-color);
        }
        .expireTimer {
          font-weight: 600;
          color: var(--semanticRed900-color);
          font-size: 14px;
          margin-top: 12px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin-right: 5px;
            path {
              stroke: var(--semanticRed900-color);
            }
          }
        }
      }
      .statusIcon{
        margin-right: 4px;
      }
    }
    &.status-top {
      padding-top: 15px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
      border-radius: 0;
      position: fixed;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) and (orientation: landscape) {
      position: absolute;
      max-width: none;
      bottom: 0;
    }
    .btn {
      margin-top: 0 !important;
    }
    &.noResultError {
      button {
        color: var(--semanticRed900-color);
        border-color: var(--semanticRed900-color);
        &.footerLink {
            color: var(--semanticRed900-color);
            border-color: var(--semanticRed900-color);
        }
      }
    }
  }
  select {
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow:ellipsis;
  }
  .bulletList {
    display: flex;
    flex-direction: column;
    min-height: 65%;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 20px 0;
    @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) and (orientation: landscape) {
      height: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 32px;
    }
    @media screen and (max-height: 600px) {
      justify-content: flex-start;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      max-width: 262px;
      width: 100%;
      li {
        color: var(--grey300-color);
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 24px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        svg {
          margin-right: 12px;
          path { 
            stroke: none;
            &:last-child{
              stroke: var(--primary500-color);
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

      }
    }
  }
  .modal-backdrop {
    background-color: var(--grey800-color);
  }
  .infoModal {
    display: inline-block;
    margin-bottom: 0 !important;
    vertical-align: middle;
    margin-left: 4px;
  }
  fieldset {
    &:disabled{
      label {
        color: var(--grey200-color);
        pointer-events: none;
        button {
          background-color: transparent !important;
          border: 0;
          color: var(--grey200-color) !important;
          filter: none !important;
        }
      }
      .input-group {
        border: 1px solid var(--grey100-color);
        .input-group-text {
          color: var(--grey200-color);
        }
      }
      .form-control {
        background: #fff;
        label {
          color: var(--grey200-color);
        }
      }
    }
    .form-control {
      &:disabled {
        opacity: 0.5;
      }
      &.error-input {
        border: 1px solid var(--semanticRed900-color);
      }
    }
  }
  .form-group {
    label {
      button {
        background: transparent;
        color: var(--grey300-color);
        border-color: transparent;
        width: auto;
        padding: 0;
        height: auto;
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        text-align: left;
        &:hover,
        &:focus {
          background: none;
          border: 0;
          outline: none;
          color: var(--grey300-color);
        }
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          background-color: transparent !important;
          border-color: transparent !important;
          color: var(--grey300-color);
        }
        svg {
          margin: 0 0 0 5px;
        }
      }
    }
  }
  .borderLine {
    background-color: var(--grey100-color);
    height: 1px;
    width: 100%;
  }
  .electricity-gas-usage-step .stepContainer,
  .energy-usage-estimation-step .stepContainer {
    padding-bottom: 124px;
  }
  .storybookQuotesCard {
    a.quotesCardLink {
      &:hover {
        text-decoration: none;
      }
      &[disabled] {
        pointer-events: none;
        .cardInner,
        .borderLine,
        .quotesBottomRow {
          opacity: 0.4;              
        }            
      }
      &:focus {
        outline: none;
        > div {
          border: 1px solid var(--grey300-color);
        }
      }      
    }
  }
  .pre-switch-review-step {
    .stepContainer {
      padding: 80px 0 160px 0;
      h1 {
        padding: 0 24px;
      }
    }
  }
  .SwitchCompleteStep {
    .stepContainer {
      padding: 24px 0 48px 0;
      h1 {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  .termsOfServiceStep {
    .stepper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      background: var(--white-color);
      box-shadow: 0 2px 4px var(--grey100-color), inset 0 -1px 0 var(--grey100-color);
      padding: 38px 40px 18px 16px;
      position: fixed;
      top: 0;
      z-index:1;
      width: 100%;
      @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
        padding: 28px 16px 9px 16px;
        height: auto;
      }      
      .backWrapper{
        height: auto !important;
      }
      .headerTitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--grey500-color);
        width: calc(100% - 24px);
      }
    }
    .stepContainer {
      margin-top: 80px;
      @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
        margin-top: 60px;
      }
      .iframeWrapper {
        height: 100vh;
        iframe {
          border: 0;
        }
      }
    }
  }  
`
