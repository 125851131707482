import { createAsyncThunk } from '@reduxjs/toolkit'

export const navigateTo = createAsyncThunk(
  'navigation/navigateTo',
  async ({ history, path }, thunkAPI) => {
    history.push(path)
    return { path: history.location.pathname }
  }
)

export const navigateBack = createAsyncThunk(
  'navigation/navigateBack',
  async ({ history }, thunkAPI) => {
    history.goBack()
    return { path: history.location.pathname }
  }
)
