import styled from 'styled-components'
import { renderToStaticMarkup } from 'react-dom/server'
import { ListGroupItem } from 'reactstrap'
import React from 'react'
import _ from 'lodash'
const createCheckedList = (colour) => {
  const CheckedList = () => {
    const fillColor = _.get(colour, 'theme.palette.primary500')
    return (
    <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill={fillColor}/>
    <path d="M7 13.0486L9.96942 16L17 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>)
  }
  const svgString = encodeURIComponent(renderToStaticMarkup(<CheckedList />))

  return `url('data:image/svg+xml;charset=UTF-8,${svgString}')`
}

const CheckedList = styled(ListGroupItem)`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    color: var(--grey500-color);
    border: none;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    &:focus {
        outline: none;
        .checkedListIcon {
            border:2px solid var(--grey800-color);
        }
    }

    .checkedIconWrap {
        width:  48px;
        height:  48px;
        background: #fff;
        border-radius: 50%;
        border: 1.5px solid var(--grey100-color);
        padding: 12px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .checkedTextWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 0;
        border-bottom: 1px dashed var(--grey100-color); 
        
        .checkedDescription {
            display: flex;
            flex-direction: column;

            .checkedListTitle {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 0;
                color: var(--grey500-color);
            }

            .checkedListSubTitle {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: var(--grey300-color);
            }
        }           

    }
    
    .checkedListIcon {
        width: 24px;
        height: 24px;
        border: 1px solid var(--grey100-color);
        box-sizing: border-box;
        border-radius: 50px;
        margin-left: auto;
    }
    &.active {
        background: transparent;
        border-top-width: 0 !important;
        margin-top: 0 !important;
        .checkedIconWrap {
            background: var(--primary100-color);
            border-color: var(--primary500-color);
        }
        .checkedListIcon {
            background: var(--primary500-color);
            background: ${(props) =>
                createCheckedList};
            background-repeat: no-repeat;
            background-position: center;
            border-color: var(--primary500-color);
        }
    }
    
    &:last-child {
        .checkedTextWrapper {
            border-bottom: none;
        }
    }
    &.property {
        .checkedList {
            font-size: 14px;
            line-height: 24px;
            z-index: 0;
        }
    }
`
export { CheckedList }
