import React from 'react'
import { StyledWidgetFooter } from './WidgetFooter.style'

export const WidgetFooter = () => {
  return (
    <StyledWidgetFooter>
      <a href='https://youtility.co.uk/terms'>Terms of Use</a>
      <span aria-hidden='true' className='dot'>·</span>
      <a href='https://youtility.co.uk/cookie-policy'>Cookie Policy</a>
      <span aria-hidden='true' className='dot'>·</span>
      <a href='https://youtility.co.uk/privacy'>Privacy Policy</a>
    </StyledWidgetFooter>
  )
}
