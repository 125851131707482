import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { StyledReviewItem } from './ReviewItem.style'
import { ReactComponent as EditIcon } from '../../images/icon_edit.svg'
import { ModalComponent } from '../../shared/components/Modal'

const DESCRIPTION_CONTENT = 'We\'ll need to do another quick search of the market if you edit this, as deals may change as a result. We’ll reuse your other answers wherever they apply to save you time.\n Would you like to proceed?'.split('\n')

const ReviewItem = ({ item, navigateTo }) => {
  const { link, title, lines, askConfirmation = false } = item
  const items = lines.map((line, index) => <div key={index} className='line'>{line}</div>)
  const [isOpen, setIsOpen] = useState(false)

  const handleOnClick = () => askConfirmation && setIsOpen(true)
  const EDIT_ICON_TEXT = `Edit ${title}`

  return (
    <>
      <StyledReviewItem>
        <div>
          <div className='title'>{title}</div>
          {items}
        </div>
        {askConfirmation
          ? <span className="editButtonWrapper" onClick={handleOnClick} aria-label={EDIT_ICON_TEXT.charAt(0).toUpperCase() + EDIT_ICON_TEXT.slice(1).toLowerCase()} tabIndex={0} onKeyPress={handleOnClick} role="button" ><EditIcon className="svgFillColor"/></span>
          : <Link to={link} aria-label={EDIT_ICON_TEXT.charAt(0).toUpperCase() + EDIT_ICON_TEXT.slice(1).toLowerCase()}><EditIcon className="svgFillColor"/></Link>}
      </StyledReviewItem>
      {isOpen && <ModalComponent
        title={'Editing this information'}
        primaryFn={() => {
          navigateTo(link)
          setIsOpen(false)
        }}
        close={() => setIsOpen(false)}
        showCancel={true}
      >
        {DESCRIPTION_CONTENT.map((description, index) => <div key={index}>{description}</div>)}
      </ModalComponent>}
    </>
  )
}

export default ReviewItem
