import React from 'react'
import { ReactComponent as YoutilityIcon } from './yt-logo.svg'
import { StyledWidgetHeader } from './WidgetHeader.style'

export const WidgetHeader = (props) => {
  const { logo } = props

  const Logo = logo

  return (
    <StyledWidgetHeader>
      <YoutilityIcon/>
      <Logo/>
    </StyledWidgetHeader>
  )
}
