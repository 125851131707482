import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { navigateTo, navigateBack } from '../middleware/navigationSlice'
import { useMemo } from 'react'

export function useNavigation() {
  const history = useHistory()
  const dispatch = useDispatch()

  return useMemo(() => {
    return {
      navigateTo: (step) => {
        dispatch(navigateTo({ history, path: step }))
      },
      navigateToStart: () => {
        dispatch(navigateTo({ history, path: '/energy' }))
      },
      navigateBack: () => {
        dispatch(navigateBack({ history }))
      }
    }
  }, [dispatch, history])
}
