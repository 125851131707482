import styled from 'styled-components'
import { Button } from 'reactstrap'

const StyledLinkButton = styled(Button)`
  background: transparent;
  border: none;
  color: var(--grey300-color);
  text-align: left;
  padding: 0;
  text-decoration: none;
  svg {
    margin-left: 4px;
  }
  .layoutLinkText {
    border-bottom: 1px dashed transparent;
  }
  &:hover, &.hover {
    color: var(--grey300-color);
    border: none;
    background: none;
    color: var(--grey300-color);
    text-decoration: none;
    focus: none;
    box-shadow: none !important;
  }
  &:focus, &.focus {
    box-shadow: none !important;
    background: transparent !important;
    color: var(--grey300-color);
    text-decoration: none;
  }
  &:active, &.active {
    border-color: transparent !important;
    background: transparent !important;
    color: var(--grey300-color);
  }
  &.footerLink {
    color: var(--primary500-color);
    text-decoration: none;
    text-align: center;
    width: 100%;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    &:hover ,
    &:active ,
    &:focus {
      color: var(--primary500-color);
    }
    &:focus {
      .linkText {
        border-bottom: 1px dashed var(--primary500-color);
      }
    }
  } 
  &:focus {
    outline: none;
    .layoutLinkText {
      border-radius: 0;
      border-bottom: 1px dashed var(--grey300-color);
    }
  }
`
export default StyledLinkButton
