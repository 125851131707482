import styled from 'styled-components'

const StyledLabelLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .labelText{
      padding: 0 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 10px;
      color: var(--grey300-color);
      text-transform: uppercase;
  }
  .line {
      padding-right: 8px;
      height: 1px;
      background: var(--grey100-color);
      width: 100%;
      margin: 3px;
      display:inline-block;
      mix-blend-mode: normal;
      margin-right: 8px;
  }
  &.sortingPrice {
      margin-top: 8px;
      @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) { 
        margin-top: 25px;
      }
  }
`

export default StyledLabelLine
