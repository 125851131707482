import React, { useEffect } from 'react'
import { WidgetHeader } from '../shared/stickee-widget/WidgetHeader'
import { Widget } from '../shared/stickee-widget/Widget'
import { WidgetFooter } from '../shared/stickee-widget/WidgetFooter'
import { ReactComponent as Logo } from './mobile.svg'

export const MobileQuotes = (props) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://whitelabels.stickeemobiles.co.uk/js/loader.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <WidgetHeader logo={Logo}/>
      <Widget widgetId='youtility-435'/>
      <WidgetFooter/>
    </>
  )
}
