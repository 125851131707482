import React, { useEffect } from 'react'
import { ModalComponent } from '../../shared/components/Modal'
import { Form, useField } from 'react-final-form'
import { useDispatch } from 'react-redux'

import SectionLabel from '../../shared/components/SectionLabel'
import SecondaryButton from '../../shared/components/Button/SecondaryButton'
import ListGroupField from '../../shared/components/SortingListGroupField'
import HorizontalCheckboxField from '../../shared/components/HorizontalCheckboxField'
import FormFooter from '../../shared/components/FormFooter'
import LinkButton from '../../shared/components/Button/LinkButton'

import { ReactComponent as CoinIcon } from '../../images/icon_coin.svg'
import { ReactComponent as StarIcon } from '../../images/sort_star.svg'
import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { useMatchedQuotes } from '../hooks/useQuotes'
import { useAnalytics } from '../hooks/useAnalytics'

export const SORT_OPTIONS = [
  {
    id: 'cost',
    label: 'Sort by price',
    description: 'Lowest price first',
    icon: <CoinIcon />,
    fn: ({ quote: quoteA }, { quote: quoteB }) => quoteA.newAnnualSpend > quoteB.newAnnualSpend ? 1 : -1
  },
  {
    id: 'rating',
    label: 'Sort by supplier rating',
    description: 'Powered by Citizens Advice',
    icon: <StarIcon />,
    iconClassName: 'strokeColor',
    fn: ({ supplier: supplierA, quote: quoteA }, { supplier: supplierB, quote: quoteB }) =>
      (supplierA?.rating?.overall || 0) > (supplierB?.rating?.overall || 0) ? -1 : (supplierA?.rating?.overall || 0) < (supplierB?.rating?.overall || 0) ? 1 : (quoteA.newAnnualSpend > quoteB.newAnnualSpend ? 1 : -1)
  }
]

const SORT_OPTIONS_BY_KEY = _.keyBy(SORT_OPTIONS, 'id')

export const FILTER_OPTIONS = [
  { id: 'green', label: 'Green tariffs', fn: ({ quote }) => quote.tariffTypes.includes('Green') },
  { id: 'fixed', label: 'Fixed tariffs', fn: ({ quote }) => quote.tariffTypes.includes('Fixed') },
  { id: 'ratedSuppliers', label: 'Highly-rated', fn: ({ supplier }) => supplier?.rating?.overall >= 3.5 },
  { id: 'exitFeeTotal', label: 'No exit fees', fn: ({ quote }) => quote.exitFeeTotal === 0 }
]

const FILTER_OPTIONS_BY_KEY = _.keyBy(FILTER_OPTIONS, 'id')

const SortAndFilterModal = (props) => {
  const { setIsModalOpen, quotes, suppliers } = props
  const dispatch = useDispatch()
  const { trackStepEvent } = useAnalytics()

  const { getMatches, filters, sort } = useMatchedQuotes('wip')
  const { matches } = getMatches({ quotes, suppliers })

  const initial = {
    sort: sort,
    filters: filters
  }

  const count = matches.length

  const handleFormSubmit = () => {
    dispatch(quotesSlice.actions.apply())
    setIsModalOpen(false)
    const labels = _.map(filters, (name) => FILTER_OPTIONS_BY_KEY[name].label)
    trackStepEvent({ name: 'quotes' }, 'filter and sort', { Filters: labels, Sort: sort })
  }

  const handleModalCloseWithoutApply = () => {
    dispatch(quotesSlice.actions.resetToLive())
    setIsModalOpen(false)
  }

  return (
    <ModalComponent close={ handleModalCloseWithoutApply }
                    showFooter={ false }
                    showHeader={ false }
                    className="sortFilterModal">
      <Form onSubmit={ handleFormSubmit } initialValues={ initial }>
        { ({ handleSubmit }) =>
          <SortAndFilterModalBody
            dispatch={ dispatch }
            handleSubmit={ handleSubmit }
            matchCount={ count }/> }
      </Form>
    </ModalComponent>
  )
}

const SortAndFilterModalBody = ({ handleSubmit, dispatch, matchCount }) => {
  const sortingField = useField('sort')
  const filtersField = useField('filters')

  const handleResetFilter = () => {
    dispatch(quotesSlice.actions.reset())
  }

  useEffect(() => {
    if (sortingField.input.value) {
      dispatch(quotesSlice.actions.sort({ sort: sortingField.input.value }))
    }
  }, [sortingField.input.value, dispatch])

  useEffect(() => {
    dispatch(quotesSlice.actions.filter({ filters: filtersField.input.value || [] }))
  }, [filtersField.input.value, dispatch])

  return (
    <form className='sortingForm' onSubmit={ handleSubmit }>
      <div className='sortingContent'>
        <div>
          <SectionLabel text={ 'SORTING' }/>
          <ListGroupField
            name='sort'
            options={ SORT_OPTIONS }
            className="sortingChecklist"
          />
        </div>
        <div>
          <SectionLabel text={ 'FILTERS' }/>
          <HorizontalCheckboxField
            options={ FILTER_OPTIONS }
            name='filters'
          />
        </div>

      </div>
      <FormFooter
        buttonElement={ SecondaryButton }
        continueBtnText={ matchCount > 0 ? `View ${matchCount} matching deals` : 'No deals match your filters' }
        className={ `status-top mb-3 ${!matchCount && 'noResultError'}` }
      >
        { (button) => (
          <>
            <LinkButton
              onClick={ handleResetFilter }
              className='status-message footerLink'
            >
              Reset filters and sort order to defaults
            </LinkButton>
            { button }
          </>
        ) }
      </FormFooter>
    </form>
  )
}

export default SortAndFilterModal
export { SortAndFilterModalBody }

const initialState = {
  wip: {
    filters: [],
    sort: 'cost'
  },
  live: {
    filters: [],
    sort: 'cost'
  },
  showAll: false
}

const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    filter(state, { payload }) {
      const { filters } = payload
      state.wip.filters = filters
      state.wip.quotes = applyCriteriaToQuotes(state.wip, state.all)
    },
    sort(state, { payload }) {
      const { sort } = payload
      state.wip.sort = sort
      state.wip.quotes = applyCriteriaToQuotes(state.wip, state.all)
    },
    reset(state) {
      state.wip.filters = initialState.wip.filters
      state.wip.sort = initialState.wip.sort
      state.wip.quotes = applyCriteriaToQuotes(state.wip, state.all)
    },
    resetToLive(state) {
      state.wip = state.live
    },
    apply(state) {
      state.live = state.wip
      state.showAll = true
    },
    showAllQuotes(state) {
      state.showAll = true
    }
  }
})

const applyCriteriaToQuotes = ({ filters, sort }, all) => {
  const filtered = applyFiltersToQuotes(filters, all)
  const sorted = applySortToQuotes(sort, filtered)
  return _.map(sorted, 'quote')
}

const applyFiltersToQuotes = (filters, all) => {
  const toApply = _.map(filters, (name) => FILTER_OPTIONS_BY_KEY[name])
  return _.filter(all, (quote) => {
    return _.every(toApply, (filter) => {
      return filter.fn(quote)
    })
  })
}

const applySortToQuotes = (sort, all) => {
  const toApply = SORT_OPTIONS_BY_KEY[sort].fn
  return all.sort(toApply)
}

export const sortByCost = (all) => {
  const mapped = _.map(all, (q) => { return { quote: q } })
  return _.map(applySortToQuotes('cost', mapped), 'quote')
}

const withSupplier = (quote, suppliers) => {
  return {
    quote: quote,
    supplier: suppliers[quote.supplierId]
  }
}

export const applyCriteria = ({ filters, sort }, { quotes, suppliers }) => {
  const all = _.map(quotes, (q) => withSupplier(q, suppliers))
  return applyCriteriaToQuotes({ filters, sort }, all)
}

export const { reducer } = quotesSlice
export const { showAllQuotes } = quotesSlice.actions
