import _ from 'lodash'
import { Development, LocalHost, Production, Staging, Test } from '../env'

const prod = {
  apiURL: window.location.origin || 'https://localhost:5001',
  apiCredentials: 'same-origin',
  analytics: {
    identifier: 'c0aa2c77fa16c766ac7b5c2c90fde22d',
    gaTrackingId: 'UA-129897386-8',
    partner: 'unknown'
  },
  debug: false,
  isEnergyDisabled: true
}

const staging = _.merge({}, prod, {
  analytics: {
    identifier: '29941727597ea82bfed47e91bf3ffc70',
    gaTrackingId: 'UA-129897386-7'
  },
  isEnergyDisabled: false
})

const test = staging

const dev = _.merge({}, test, {
  analytics: {
    debug: true
  }
})

const localhost = _.merge({}, dev, {
  // apiURL: 'https://app.dev.youtility.co.uk',
  apiCredentials: 'include',
  analytics: {
    disabled: true,
    debug: true
  },
  debug: true
})

const byEnvironment = {
  [LocalHost]: localhost,
  [Development]: dev,
  [Test]: test,
  [Staging]: staging,
  [Production]: prod
}

export function defaultConfigFor (client, environment) {
  const defaults = byEnvironment[environment]
  return _.merge({}, defaults, {
    analytics: {
      partner: _.upperFirst(client.toLowerCase())
    },
    environment: environment
  })
}
