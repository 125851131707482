import { configureStore } from '@reduxjs/toolkit'
import { config } from './config/config'

import {
  reducerPath as apiReducerPath,
  reducer as apiReducer,
  middleware as apiMiddleware
} from './energy/api'
import { reducer as energyReducer } from './energy/middleware/energySlice'
import { reducer as analyticsReducer } from './middleware/analyticsSlice'
import { reducer as quotesReducer } from './energy/steps/SortAndFilter'
import { reducer as preSwitchReviewReducer } from './energy/components/PreSwitchCollapse/PreSwitchCollapse'
import { reducer as addressesReducer } from './energy/middleware/addresses.slice'
import { analyticsMiddleware } from './middleware/analyticsMiddleware'

export * from './energy/middleware/addresses.slice'

const store = configureStore({
  reducer: {
    [apiReducerPath]: apiReducer,
    analytics: analyticsReducer,
    energy: energyReducer,
    quotes: quotesReducer,
    preSwitchReview: preSwitchReviewReducer,
    addresses: addressesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiMiddleware, analyticsMiddleware(config))
})

export default store
