import React, { useCallback } from 'react'
import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import AccordionGroup from '../../../shared/components/AccordionGroup/AccordionGroup'
import { StyledPreSwitchReview, StyledPreSwitchWrapper } from './PreSwitchCollapse.style'
import { toFriendlyTitle } from '../../stepUtils'
import ReviewItem from '../ReviewItem'
import { convertToCamelCase, convertToYearAndMonths } from '../../../utils/helpers'
import { YEAR_DROPDOWN_OPTIONS, MONTH_DROPDOWN_OPTIONS } from '../../../utils/constants'
import { ReactComponent as Payment } from '../../../images/payment.svg'
import { ReactComponent as Meter } from '../../../images/meter.svg'
import { ReactComponent as Energy } from '../../../images/energy.svg'
import { ReactComponent as Person } from '../../../images/person.svg'
import { useSelector, useDispatch } from 'react-redux'

const ICONS = {
  paymentInformation: <Payment className="svgStrokeColor"/>,
  currentEnergy: <Meter className="svgStrokeColor"/>,
  newEnergy: <Energy className="svgStrokeColor"/>,
  aboutYou: <Person className="svgStrokeColor"/>
}

const PreSwitchCollapse = ({ sections, navigateTo }) => {
  const dispatch = useDispatch()
  const ASK_CONFIRMATION_STEPS = new Set([
    // 'SupplyPostcodeStep',
    'SupplyAddressStep', 'CurrentProviderStep', 'FuelTypeStep', 'EnergyUsageEstimationStep', 'TariffPreferencesStep', 'NightConsumptionStep', 'ElectricityGasUsageStep'])
  const CONVERT_TO_MONTH_AND_YEAR_STEPS = new Set([
    // 'MonthsAtSupplyAddressStep',
    'MonthsAtPreviousAddress1Step',
    'MonthsAtPreviousAddress2Step'])
  const getButtonBody = (title) => {
    const key = convertToCamelCase(title)
    return <>
      {ICONS[key]}
      <div>{title}</div>
    </>
  }

  const getMonthAndYear = (totalMonths) => {
    const { years, months } = convertToYearAndMonths(totalMonths)
    const yearOption = _.find(YEAR_DROPDOWN_OPTIONS, { id: `${years}` })
    const monthOption = _.find(MONTH_DROPDOWN_OPTIONS, { id: `${months}` })
    return [`${years > 0 ? yearOption.label : ''} ${monthOption ? monthOption.label : ''}`]
  }

  const getCollapseBody = (groups, id) => {
    const modifiedGroup = groups
      .map(({ step, header, lines }) => {
        return {
          link: `/energy/${step}?skipAhead=true`,
          title: header || toFriendlyTitle(step),
          lines: CONVERT_TO_MONTH_AND_YEAR_STEPS.has(step) ? getMonthAndYear(lines) : lines,
          askConfirmation: ASK_CONFIRMATION_STEPS.has(step)
        }
      })
    return (<StyledPreSwitchReview data-testid={`${id}Collapse`}>{modifiedGroup.map((item, index) => (
      <ReviewItem key={index} item={item} navigateTo={navigateTo} />
    ))}</StyledPreSwitchReview>)
  }

  const data = sections.map(s => {
    return {
      id: s.id,
      header: getButtonBody(s.title),
      body: getCollapseBody(s.groups, s.id)
    }
  })

  const selectedSections = useSelector((state) => state.preSwitchReview.selectedSections)
  const handleSection = useCallback(
    (payload) => {
      dispatch(preSwitchSlice.actions.selectSection(payload))
    }, [dispatch]
  )
  return (<StyledPreSwitchWrapper>
    <AccordionGroup
      data={data}
      canExpandMultiple={false}
      expandedSectionsIds={selectedSections}
      setExpandedSectionsIds={handleSection}
    />

  </StyledPreSwitchWrapper>
  )
}

export default PreSwitchCollapse
const initialState = { selectedSections: [] }
const preSwitchSlice = createSlice({
  name: 'preSwitches',
  initialState,
  reducers: {
    selectSection(state, { payload }) {
      state.selectedSections = payload
    }
  }
})
export const { reducer } = preSwitchSlice
