import React from 'react'
import { Container } from 'reactstrap'
import PropTypes from 'prop-types'

const Layout = (props) => (
  <Container fluid className="noPad">
    {props.children}
  </Container>
)

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}
