import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { themeFor } from '../config/defaults/theme'
import _ from 'lodash'

const verticalFromPath = (path) => {
  return _.trimStart(path, '/').split('/')[0]
}

function themeForPath (path) {
  const vertical = verticalFromPath(path)
  return themeFor(vertical)
}

export const RouteThemeProvider = ({ children }) => {
  const location = useLocation()
  const [theme, setTheme] = useState(themeForPath(location.pathname))
  const [vertical, setVertical] = useState(null)

  useEffect(() => {
    const theme = themeFor(vertical)
    setTheme(theme)
  }, [vertical])

  useEffect(() => {
    const vertical = verticalFromPath(location.pathname)
    setVertical(vertical)
  }, [location.pathname])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
