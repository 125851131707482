import styled from 'styled-components'
import { Button } from 'reactstrap'

const StyledButtonDefaults = styled(Button)`
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--primary500-color);
  height: 48px;
  margin-top: 20px;

  outline: none !important;
  box-shadow: none !important;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;

  @media screen and (max-width: 320px) {
    max-width: 300px;
  }

  &.small{
    width: auto;
    padding: 10px 18px;

    &:hover,  &.hover, &.disabled {
      padding: 9px 17px;
    }
    
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid currentColor;
    border-right-color: transparent;
  }
`

export default StyledButtonDefaults
