import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'

export const api = createApi({
  reducerPath: 'energyApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.apiURL, credentials: config.apiCredentials }),
  tagTypes: ['Step'],
  keepUnusedDataFor: 5 * 60,
  endpoints: (builder) => ({
    getStep: builder.query({
      query: (name) => `api/energy/workflow/steps/${name}`,
      providesTags: (result, error, name) => [{ type: 'Step', name }]
    }),
    submitStep: builder.mutation({
      query: ({ name, submissionValues, skipAhead }) => {
        const params = {}
        if (skipAhead !== undefined && skipAhead !== null) {
          params.skipAhead = skipAhead
        }
        return {
          url: `api/energy/workflow/steps/${name}`,
          params: params,
          method: 'POST',
          body: { values: submissionValues }
        }
      },
      invalidatesTags: (result, error, name) => {
        if (error) {
          return []
        }
        return [{ type: 'Step', name }]
      }
    }),
    getWorkflow: builder.query({
      query: () => 'api/energy/workflow'
    }),
    createWorkflow: builder.mutation({
      query: (name, ...data) => ({
        url: 'api/energy/workflow',
        method: 'POST',
        body: data
      })
    }),
    getReview: builder.query({
      query: () => 'api/energy/workflow/review'
    }),
    getQuotes: builder.query({
      query: () => 'api/energy/workflow/quotes'
    })
  })
})

export const {
  useGetStepQuery,
  useLazyGetStepQuery,
  useSubmitStepMutation,
  useCreateWorkflowMutation,
  useGetReviewQuery,
  useGetQuotesQuery,
  endpoints,
  reducerPath,
  reducer,
  middleware
} = api
