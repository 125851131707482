import React from 'react'
import { LandingScreen } from '../shared/LandingScreen'
import { useNavigation } from '../hooks/useNavigation'
import { useLocation } from 'react-router-dom'

const LANDING_SCREEN_LIST = [
  <>Deals <strong>from just £5</strong> per month</>,
  <>Contracts from <strong>1 to 36 months</strong></>,
  <><strong>Mobile phone & SIM only</strong> offers</>,
  <>Get deals with <strong>gifts & cashback</strong></>
]

export const MobileLandingScreen = () => {
  const { navigateTo } = useNavigation()
  const { search } = useLocation()

  const onTap = () => {
    navigateTo('/mobile/deals' + search)
  }

  return (
    <LandingScreen
      title={'Save on your mobile bill'}
      description={<>Seven out 10 people can <strong>save over £200 a year</strong> on their mobile deal.</>}
      bullets={LANDING_SCREEN_LIST}
      action={onTap}
      actionLabel={'Compare the latest deals'}
      isLoading={false}
    />
  )
}
