import styled from 'styled-components'
import { Modal } from 'reactstrap'

const StyledSortingModal = styled(Modal)`
    .modal-content{
        max-width: 440px;
        margin: 0 auto;
        box-shadow: 0 2px 4px var(--black100-color);
        border-radius: 5px;
        border: 0;
        @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
            max-width: calc(100% - 24px);
        }
        .modal-header {
            border-bottom: 0;
            padding: 24px 24px 0;
            color: var(--grey500-color);
            .close {
                display: none;
            }
            .modal-title{
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
            }
        }
        .modal-body {
            color: var(--grey300-color);
            font-size: 14px;
            line-height: 24px;
            padding: 12px 24px 16px 24px;
            > div {
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .modal-footer {
            justify-content: center;
            border-top: 1px solid var(--grey100-color);
            padding: 12px 24px;
            button {
                margin-top: 0;
                height: auto;
                background: transparent;
                border-width: 0;
                color: var(--primary500-color);
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                padding: 0;
                margin: 0;
                border-bottom: 1px dashed transparent;
                &:hover,
                &:focus {
                    border-radius: 0;
                    width: auto; 
                    text-decoration-line: underline;
                    text-decoration-color: var(--primary500-color);
                    text-decoration-style: dashed;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 6px;
                },
                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled).active {
                    background-color: transparent !important;
                    border-color: transparent !important;
                    color: var(--primary500-color) !important;
                }
                
            }
            &.cancelButton {
                padding: 0;
                button {
                    width: 50%;
                    border-radius: 0;
                    padding: 16px 24px;
                    &:first-child {
                        border-right: 1px solid var(--grey100-color);
                        font-weight: 400;
                    }
                }
            }
             &.okayButton {
                padding: 0;
                button {
                    width: 100%;
                    border-radius: 0;
                    padding: 16px 24px;
                }
            }
    }
    &.sortFilterModal {
        width: 375px;
        max-width: 100%;
        margin: auto;
        height: 100%;
        @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) { 
            width: 100%;
            align-items: flex-end;
            padding-bottom: 120px;
        }

        .modal-content {
            width: 576px;
            max-width: 576px;
            margin: 0 auto;
            @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) { 
                width: 100%;
                height: auto;
                border-radius: 12px 12px 0 0;
            }
        }
        .modal-header {
            padding: 0;
        }
        .modal-body {
            padding: 0;
            padding-top: 12px;
            @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
                margin-bottom: 4px;
            }
            
            .sortingForm {
                min-height: 481px;
                max-height: 537px;
                display: flex;
                flex-direction: column;
                padding-bottom: 124px;
                position: relative;
                @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) { 
                    padding-bottom: 0;
                    height: auto;
                    min-height: auto;
                }

                .sortingContent {
                    flex: 1;
                    overflow: auto;
                    padding: 20px 24px 0 24px;

                    .list-group {
                        margin: 4px 0 20px 0;
                        li:hover {
                            background-color: transparent;
                            .checkedListIcon {
                                border: 1px solid var(--grey500-color);
                                box-shadow: inset 0 0 0 1px var(--grey500-color);
                            }
                        }
                        li:focus {
                            outline: none;
                        }
                    }
                }

                .footer-container {
                    border-radius: 0 0 12px 12px;
                    @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {  
                        border-radius: 0;
                    }
                }
                
            }
        }
    }
`

export default StyledSortingModal
