import mixpanel from 'mixpanel-browser'
import {
  name,
  identify,
  incrementPeopleProperty,
  initialize,
  setOncePeopleProperties,
  setPeopleProperties,
  trackEvent
} from './analyticsSlice'

export const analyticsMiddleware = (config) => (store) => (next) => (action) => {
  if (!action.type.startsWith(name)) {
    return next(action)
  }

  const instance = getAnalyticsInstance(config)

  const { environment, analytics } = config
  const { identifier, debug, partner } = analytics

  switch (action.type) {
    case initialize.type: {
      instance.init(identifier, { debug: debug })
      const superProps = {
        Partner: partner,
        Environment: environment,
        'App Version': process.env.REACT_APP_VERSION
      }
      instance.register(superProps)
      break
    }
    case trackEvent.type: {
      const { title, properties } = action.payload
      instance.track(title, properties)
      break
    }
    case setPeopleProperties.type: {
      const { data } = action.payload
      instance.people.set(data)
      break
    }
    case setOncePeopleProperties.type: {
      const { data } = action.payload
      instance.people.set_once(data)
      break
    }
    case incrementPeopleProperty.type: {
      const { key } = action.payload
      instance.people.increment(key)
      break
    }
    case identify.type: {
      const { email } = action.payload
      instance.identify(email)
      instance.people.set({ $email: email })
      break
    }
  }

  return next(action)
}

const getAnalyticsInstance = (config) => {
  if (!config.analytics.disabled) {
    return mixpanel
  }
  return {
    init: (token) => {
      console.log('Mixpanel IGNORING init', 'token', token)
    },
    register: (properties) => {
      console.log('Mixpanel IGNORING register', 'properties', properties)
    },
    track: (title, properties) => {
      console.log(
        'Mixpanel IGNORING track',
        'title',
        title,
        'properties',
        properties
      )
    },
    people: {
      set: (properties) => {
        console.log('Mixpanel IGNORING people.set', 'properties', properties)
      },
      set_once: (properties) => {
        console.log(
          'Mixpanel IGNORING people.set_once',
          'properties',
          properties
        )
      },
      increment: (key) => {
        console.log('Mixpanel IGNORING people.increment', 'key', key)
      }
    },
    identify: (id) => {
      console.log('Mixpanel IGNORING identify', 'id', id)
    },
    get_distinct_id: () => '[unknown]'
  }
}
