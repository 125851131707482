import styled from 'styled-components'

export const StyledLandingScreen = styled.div`
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height:100%;
  margin-bottom:0;
  flex:1;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    max-height: 600px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    justify-content: space-between;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) and (orientation: landscape) {
    overflow-y: auto;
  }
  
  &.desktopLayout {
    margin-bottom: 0;
  }
  
  .innerSection {
    margin: 40px auto 96px;
    width: 100%;
    max-width: 295px;
    display: flex;
    flex-direction: column;
    flex: 1;
    
    .bulletList {
      min-height: 0;
    }   
    
    .bulletList > ul{
      max-width: 295px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) {
      width: 100%;
      max-width: 295px;
    }
    h1 {
      color: var(--grey500-color);
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin: 24px 0 15px 0;
    }
    p {
      font-size: 14px;
      color: var(--grey300-color);
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .footerLink {
    text-align: center; 
    font-size: 12px; 
    color: var(--grey300-color); 
    font-weight: 400; 
    padding: 8px 0px 0px 0px;
    line-height: 20px;
    
    .a:hover, a:visited, a:active, a:link {
      color: var(--primary500-color);
      font-weight: 500;
      text-decoration: underline;
    }
  }
  
`
export const AppLogo = styled.div`
  background-image: url(${props => props.theme.landing.logo});
  height: 51px;
  min-height: 51px;
  background-size: contain;
  background-repeat: no-repeat;
  flex: 0 0 auto;
`
