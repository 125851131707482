import styled from 'styled-components'
import { FormGroup } from 'reactstrap'

const StyledCheckboxGroup = styled(FormGroup)`
width: 100%;
.selectionTitle {
  width: 100%;
  position: relative;
  span {
    color: var(--primary500-color);
    border: 1px solid var(--primary500-color);
    border-radius: 50%;
    width: 14px;
    height: 14px;
    font-size: 11px;
    padding: 2px;
    line-height: 8px;
    display: inline-block;
    text-align: center;
  }
}

.checkboxOption {
  display: inline-block;
  padding: 0;
  margin-bottom: 12px;

  @media screen and (max-width: 320px) {
    margin-right: 12px;
  }

  &.last {
    margin-right: 0;
    margin-bottom: 0;
  }

  .form-check-label {
    width: 100%;
    position: relative;
    cursor: pointer;
  }

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    right: 0;
    + span {
      background: var(--white-color);
      border: 1px solid var(--grey200-color);
      color: var(--grey300-color);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 11px 0;
      display: block;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      text-align: center;
      height: 48px;
      @media (max-width: 385px) {
        padding: 11px 15px;
      }
    }
    &:hover + span {
      border: 1px solid var(--grey500-color);
      box-shadow: inset 0 0 0 1px var(--grey500-color);
    }
    &:focus + span {
      border: 1px solid var(--grey500-color);
      box-shadow: inset 0 0 0 1px var(--grey500-color);
    }
    &:checked + span {
      background: var(--primary100-color);
      box-sizing: border-box;
      border-radius: 8px;
      color: var(--primary500-color);
      border: 1px solid var(--primary500-color);
      -webkit-box-shadow: inset 0 0 0 1px var(--primary500-color);
      -moz-box-shadow: inset 0 0 0 1px var(--primary500-color);
      box-shadow: inset 0 0 0 1px var(--primary500-color);
    }
    &:disabled + span {
      opacity: 0.6;
    }    
  }
}
`

export default StyledCheckboxGroup
