import styled from 'styled-components'

const LabelHintWrapper = styled.div`
    display: flex;
    align-items: center;
    &.info-label {
        label {
            color: var(--grey500-color);
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            button {
                display: inline;
                color: var(--grey500-color);
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                .linkText {
                    margin-left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .layoutLinkText {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: var(--grey500-color);
                    margin-left: 0;
                }
                &:hover,
                &:focus,
                &:active {
                    color: var(--grey500-color); 
                    box-shadow: none;
                    background-color: transparent;
                    border-color: transparent;
                    box-shadow: none;
                }
                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--grey500-color); 
                        box-shadow: none;
                        background-color: transparent;
                        border-color: transparent;
                        box-shadow: none;
                    } 
                }
                &:focus {
                    outline: none;
                    .layoutLinkText {
                        border-bottom: 1px dashed var(--grey500-color);
                        border-radius: 0;
                    }
                }
                img {
                    margin-left: 4px;
                }
            }
        }
    }
    span {
        margin-left: 4px;
        color: var(--grey300-color);
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
    }
`
export default LabelHintWrapper
