import React from 'react'
import { LandingScreen } from '../shared/LandingScreen'
import { useNavigation } from '../hooks/useNavigation'
import { useLocation } from 'react-router-dom'

const LANDING_SCREEN_LIST = [
  <>Compare <strong>over 95%</strong> of the market</>,
  <>Contracts from <strong>1 to 24 months</strong></>,
  <>Add <strong>TV, home phone and more</strong></>,
  <>Get deals with <strong>gifts & cashback</strong></>
]

export const BroadbandLandingScreen = () => {
  const { navigateTo } = useNavigation()
  const { search } = useLocation()

  const onTap = () => {
    navigateTo('/broadband/deals' + search)
  }

  return (
    <LandingScreen
      title={'Save on your broadband bill'}
      description={<>Many homes can <strong>save over £200 a year</strong> by switching broadband provider.</>}
      bullets={LANDING_SCREEN_LIST}
      action={onTap}
      actionLabel={'Compare the latest deals'}
      isLoading={false}
    />
  )
}
