import styled from 'styled-components'
import StyledButtonDefaults from '../StyledButtonDefaults'

const StyledSecondaryButton = styled(StyledButtonDefaults)`
  color: var(--primary500-color);
  background-color: var(--white-color);

  &:hover, &.hover {
    background: var(--white-color);
    border: 3px solid var(--primary500-color);
    color: var(--primary500-color);
  }

  &:focus, &.focus {
    background: var(--primary100-color);
    border: 2px solid var(--primary500-color);
    color: var(--primary500-color);
    padding: 10px 17px;
  }

  &:active, &.active {
    border-color: var(--primary500-color) !important;
    background: var(--primary100-color) !important;
    color: var(--primary500-color) !important;
  }

  &:disabled, &.disabled {
    color: var(--grey100-color);
    background-color: var(--white-color) !important;
    border: 2px solid var(--grey100-color);
    opacity: 1;
  }

  &.loading {
    background: var(--primary100-color) !important;
  }

  &.loading:disabled, &.loading.disabled {
    color: var(--primary500-color);
    border: 2px solid var(--primary500-color);
    opacity: 1;
  }

  &.pillButton {
    width: auto;
    border-radius: 32px;
    padding: 2px 10px;
    font-size: 14px;
    height: 32px;

    &:hover,  &.hover, &.disabled {
      padding: 1px 9px;
    }
  }

  &.small{
    &.loading:disabled, &.loading.disabled {
      color: var(--primary500-color);
      border: 2px solid var(--primary500-color);
      opacity: 1;
    }
  }
`

export default StyledSecondaryButton
