import styled from 'styled-components'

export const StyledWidgetFooter = styled.div`
  background-color: #F6F9FF;
  padding-top: 67px;
  padding-bottom: 35px;
  text-align: center;
  
  a {
    padding:8px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: ${({ theme }) => theme.palette.primary500};


  }
  
  span.dot {
    color: ${({ theme }) => theme.palette.grey200};
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    speak: none;
  }
`
