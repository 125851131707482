import _ from 'lodash'

// The key into this map has to match the url component
const Clients = Object.assign(
  {}
)

function entryForClient (client) {
  return _.merge({ config: {}, theme: {} }, Clients[client])
}

export function clientConfigFor (client, environment) {
  return entryForClient(client).config[environment] || {}
}

export function clientThemeFor (client) {
  return entryForClient(client).theme || {}
}

export function lookupClient (components) {
  if (!components || components.length === 0) {
    return 'www'
  }
  const found = Object.keys(Clients).find((key) =>
    components.some((c) => key.toLowerCase() === c.toLowerCase())
  )
  return found || components[0]
}
