import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  identify,
  incrementPeopleProperty,
  setOncePeopleProperties,
  setPeopleProperties,
  trackEvent
} from '../../middleware/analyticsSlice'
import { quoteInStep, supplierForQuote } from '../stepUtils'

export function useAnalytics() {
  const workflowId = useSelector((state) => _.get(state, 'energy.workflowId', null))
  const vertical = 'Energy'
  const dispatch = useDispatch()

  return {
    trackLandingScreenDisplayed: (stepName) => {
      const title = toStepEventTitle(stepName, 'displayed')
      const data = withVertical(vertical)
      dispatch(trackEvent(title, data))
    },
    // here we pass in the workflowId as it isn't always populated in redux by the time we need immediately after creating
    trackLandingScreenSubmitted: (stepName, workflowId) => {
      const title = toStepEventTitle(stepName, 'tapped CTA')
      const data = Object.assign(
        {},
        withVertical(vertical),
        withWorkflowId(workflowId)
      )
      dispatch(trackEvent(title, data))
    },
    trackQuoteDetailsDisplayed: (step, quoteId) => {
      if (!workflowId) return
      const quote = quoteInStep(step, quoteId)
      if (!quote) return
      const supplier = supplierForQuote(step, quoteId) || {}
      const title = toStepEventTitle('quote details', 'displayed')
      const data = Object.assign(
        {},
        withVertical(vertical),
        withWorkflowId(workflowId),
        withQuoteId(quoteId),
        withQuoteDetails(quote, supplier, step.quotes.searchProfile.annualSpend, step.quotes.searchResults.length)
      )
      dispatch(trackEvent(title, data))
    },
    trackQuoteDetailsSubmitted: (step, quoteId) => {
      if (!workflowId) return
      const quote = quoteInStep(step, quoteId)
      if (!quote) return
      const supplier = supplierForQuote(step, quoteId) || {}
      const title = toStepEventTitle('quote details', 'submitted')
      const data = Object.assign(
        {},
        withVertical(vertical),
        withWorkflowId(workflowId),
        withQuoteId(quoteId),
        withQuoteDetails(quote, supplier, step.quotes.searchProfile.annualSpend, step.quotes.searchResults.length)
      )
      dispatch(trackEvent(title, data))
    },
    trackSwitchCompleteDisplayed: (step) => {
      const title = toStepEventTitle('switch complete', 'displayed')
      const data = Object.assign(
        {},
        withVertical(vertical),
        withWorkflowId(workflowId),
        withQuoteId(step.quoteId),
        withSwitchDetails(step)
      )
      dispatch(trackEvent(title, data))
    },
    trackSupplierContactMethodClicked: (step, provider, method) => {
      if (!workflowId) return
      const title = toStepEventTitle('switch complete - contact', method)
      const data = Object.assign(
        {},
        withVertical(vertical),
        withWorkflowId(workflowId),
        withQuoteId(step.quoteId),
        { 'New provider': provider }
      )
      dispatch(trackEvent(title, data))
    },
    trackStepDisplayed: (step) => {
      if (!workflowId) return
      const title = toStepEventTitle(step.name, 'displayed')
      const data = Object.assign(
        {},
        withVertical(vertical),
        withWorkflowId(workflowId),
        withQuoteId(step.quoteId)
      )
      dispatch(trackEvent(title, data))
    },
    trackStepSubmitted: (step, values) => {
      if (!workflowId) return
      const title = toStepEventTitle(step.name, 'submitted')
      const friendlyValues = toStepEventProperties(values)
      const data = Object.assign(
        {},
        withVertical(vertical),
        withWorkflowId(workflowId),
        withQuoteId(step.quoteId),
        friendlyValues
      )
      dispatch(trackEvent(title, data))
    },
    trackStepEvent: ({ name, quoteId = null }, suffix, other = {}) => {
      if (!workflowId) return
      const title = toStepEventTitle(name, suffix)
      const data = Object.assign(
        {},
        withVertical(vertical),
        withWorkflowId(workflowId),
        withQuoteId(quoteId),
        other
      )
      dispatch(trackEvent(title, data))
    },
    setPeopleProperties: (properties) => {
      const friendlyValues = toPeopleProperties(vertical, properties)
      dispatch(setPeopleProperties(friendlyValues))
    },
    setOncePeopleProperties: (properties) => {
      const friendlyValues = toPeopleProperties(vertical, properties)
      dispatch(setOncePeopleProperties(friendlyValues))
    },
    incrementPeopleProperty: (key) => {
      dispatch(incrementPeopleProperty(toPeoplePropertyKey(vertical, key)))
    },
    identify: (email) => {
      dispatch(identify(email))
    }
  }
}

const toStepEventTitle = (stepName, suffix) => {
  const withoutValueSuffix = _.replace(stepName, 'Step', '')
  const friendlyStepName = _.join(_.words(withoutValueSuffix), ' ')
  return _.toLower(_.join([friendlyStepName, suffix], ' - '))
}

const toFriendlyPropertyName = (name) => {
  return _.capitalize(_.startCase(_.replace(name, 'Value', '')))
}

const toPeopleProperties = (vertical, values) => {
  return _.mapKeys(toStepEventProperties(values), (value, key) => toPeoplePropertyKey(vertical, key))
}

const toPeoplePropertyKey = (vertical, key) => {
  return key + ' (' + vertical + ')'
}

const toStepEventProperties = (values) => {
  return _.mapKeys(values, (value, key) => toFriendlyPropertyName(key))
}

const withWorkflowId = (workflowId) => ({ 'Workflow id': workflowId })
const withVertical = (vertical = 'Energy') => ({ Vertical: vertical })
const withQuoteId = (quoteId) => (quoteId ? { 'Quote id': quoteId } : {})
const withQuoteDetails = (quote, supplier, currentAnnualSpend, totalQuotes) => {
  const contacts = _
    .chain([
      ['Email', supplier.customerServiceEmail],
      ['Phone', supplier.customerServicePhone],
      ['Website', supplier.homePageUrl]
    ])
    .reject(([_key, value]) => {
      _.isNil(value)
    })
    .map(([key, _value]) => key)
    .value()

  const {
    tariffName,
    paymentType,
    newAnnualSpend,
    exitFeeTotal,
    discounts,
    tags,
    savingsPerYear,
    isSwitchable,
    tariffEndDateGas,
    tariffEndDateElec
  } = quote

  return Object.assign({
    'New provider name': supplier.name,
    'New tariff name': tariffName,
    'New provider payment type': paymentType,
    'New annual tariff': newAnnualSpend,
    'Current annual tariff': currentAnnualSpend,
    'Annual saving or increase': savingsPerYear,
    'Blended rating': supplier.rating?.overall,
    'Exit fee?': exitFeeTotal > 0,
    // 'Fixed?': isFixed(quote),
    // 'Green?': isGreen(quote),
    '# of total quotes displayed': totalQuotes,
    Discounts: discounts,
    'Quote tags': tags,
    'Is switchable': isSwitchable,
    'Provider contact info': contacts
  },
  endDate(tariffEndDateGas, tariffEndDateElec))
}

const withSwitchDetails = ({
  // quote,
  supplier
}) => {
  // const {
  //   tariffName,
  //   paymentType,
  //   newAnnualSpend,
  //   exitFeeTotal,
  //   discounts,
  //   tags,
  //   savingsPerYear,
  //   tariffEndDateGas,
  //   tariffEndDateElec
  // } = quote

  // const currentAnnualSpend = newAnnualSpend + savingsPerYear

  return Object.assign({
    'New provider name': supplier?.name,
    // 'New tariff name': tariffName,
    // 'New provider payment type': paymentType,
    // 'New annual tariff': newAnnualSpend,
    // 'Current annual tariff': currentAnnualSpend,
    // 'Annual saving or increase': savingsPerYear,
    'Blended rating': supplier?.rating?.overall
    // 'Exit fee?': exitFeeTotal > 0,
    // 'Fixed?': isFixed(quote),
    // 'Green?': isGreen(quote)
    // Discounts: discounts,
    // 'Quote tags': tags
  }
    // endDate(tariffEndDateGas, tariffEndDateElec)
  )
}

// const isFixed = (quote) => _.includes(quote.tariffTypes, 'Fixed')
// const isGreen = (quote) => _.includes(quote.tariffTypes, 'Green')

const endDate = (tariffEndDateGas, tariffEndDateElec) => {
  if (_.isNil(tariffEndDateGas) && _.isNil(tariffEndDateElec)) {
    return {}
  }
  if (tariffEndDateGas === tariffEndDateElec) {
    return {
      'New contract end date': new Date(tariffEndDateGas).toISOString()
    }
  }
  return {
    'New contract end date (gas)': tariffEndDateGas ? new Date(tariffEndDateGas).toISOString() : '',
    'New contract end date (elec)': tariffEndDateElec ? new Date(tariffEndDateElec).toISOString() : ''
  }
}
