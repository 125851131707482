import LandingBackground from '../../images/home_page_background.svg'
import YoutilityIcon from '../../images/landing_logo.svg'
import FlowIcon from '../../images/youtility_logo.svg'
import { clientTheme } from '../config'

const defaultTheme = () => {
  return {
    landing: {
      skip: false,
      logo: YoutilityIcon,
      background: LandingBackground,
      bullets: {
        fillColour: '#F4F3FF',
        strokeColour: '#5856D6'
      }
    },
    breakpoints: {
      values: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1280
      }
    },
    palette: {
      green900: '#197D64',
      red900: '#CD2952',
      grey800: '#1E2841',
      grey500: '#455682',
      grey300: '#5F719D',
      grey200: '#A5B2CE',
      grey100: '#DBE2F3',
      grey050: '#F6F9FF',
      primary050: '#004B88',
      primary500: '#5856D6',
      primary300: '#E9E6FF',
      primary100: '#F4F3FF',
      secondary500: '#59C7FB',
      secondary100: '#EEF9FE',
      tertiary: '#E40087',
      semanticRed900: '#CD2952',
      semanticRed500: '#EB5D80',
      semanticRed100: '#FFEBF0',
      semanticGreen900: '#197D64',
      semanticGreen500: '#3ACB83',
      semanticGreen100: '#ECF7EC',
      semanticYellow900: '#827025',
      semanticYellow500: '#FFCC00',
      semanticYellow100: '#FFF7D6',
      black: '#000000',
      black100: 'rgba(0, 0, 0, 0.1)',
      white: '#ffffff',
      white010: 'rgba(255, 255, 255, 0.001)'
    },
    logo: FlowIcon
  }
}

const VerticalThemes = {
  energy: {
    quoteList: {
      tags: {
        textColour: '#5856D6',
        fillColour: '#5856D61A'
      }
    }
  }
}

export default defaultTheme

export const themeFor = (vertical) => {
  return Object.assign(
    {},
    defaultTheme(),
    VerticalThemes[vertical?.toLowerCase()] || {},
    clientTheme
  )
}
