import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import store from './store'
import { unregister } from './registerServiceWorker'
import { initialize as initializeAnalytics } from './middleware/analyticsSlice'
import { RouteThemeProvider } from './shared/RouteThemeProvider'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')

store.dispatch(initializeAnalytics())

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <RouteThemeProvider>
        <App />
      </RouteThemeProvider>
    </BrowserRouter>
  </Provider>,
  rootElement
)

unregister()
