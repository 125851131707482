import styled from 'styled-components'
import StyledCheckboxGroup from '../StyledCheckboxGroup'
import tickIcon from '../../../../../shared/assets/icon_tick.svg'

const StyledHorizontalCheckbox = styled(StyledCheckboxGroup)`
display: inline-block;
margin: 12px 0;

.checkboxOption {
  margin-top: 4px;
  margin: 4px 6px;
  margin-bottom: 8px;
  width: calc((100% - 12px) / 2);
  &:nth-child(2n) {
    margin-right: 0;
  }
  &:nth-child(2n+1) {
    margin-left: 0;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px) { 
    @media (max-width: 365px) {
      width: 100%;
      margin: 10px 0;
    }
  }
}
.showIcon {
  .customCheckboxBtn {
    padding-left: 44px !important;
    &:before {
      border: 1px solid;
      border-radius: 4px;
      box-sizing: border-box;
      height: 16px;
      left: 16px;
      position: absolute;
      top: 0;
      bottom:0;
      margin: auto;
      width: 16px;
      content: '';
      background-color: #fff;
      border-color: var(--grey200-color);
    }
    &:after {
      border: 1px solid;
      border-radius: 4px;
      box-sizing: border-box;
      height: 16px;
      left: 16px;
      position: absolute;
      top: 0;
      bottom:0;
      margin: auto;
      width: 16px;
      content: '';
      background-color: #fff;
      border-color: var(--grey200-color);
      background: url(${tickIcon});
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .form-check-input{
    &:checked + .customCheckboxBtn {
      &::after{
        background-color: var(--primary500-color);
        border-color: var(--primary500-color);
        transform: scale(1);
        transition: transform ease 280ms, background-color ease 280ms;
      }
      &::before {
        border-color: var(--primary500-color);
      }
    }
  }
}
`

export default StyledHorizontalCheckbox
