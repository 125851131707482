import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const noOp = () => {}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    initialize: noOp,
    trackEvent: {
      reducer: noOp,
      prepare: (title, properties) => {
        return { payload: { title, properties } }
      }
    },
    setPeopleProperties: {
      reducer: noOp,
      prepare: (data) => {
        return { payload: { data } }
      }
    },
    setOncePeopleProperties: {
      reducer: noOp,
      prepare: (data) => {
        return { payload: { data } }
      }
    },
    incrementPeopleProperty: {
      reducer: noOp,
      prepare: (key) => {
        return { payload: { key } }
      }
    },
    identify: {
      reducer: noOp,
      prepare: (email) => {
        return { payload: { email } }
      }
    }
  }
})

export const { name, reducer } = analyticsSlice
export const { initialize, trackEvent, setPeopleProperties, setOncePeopleProperties, incrementPeopleProperty, identify } = analyticsSlice.actions
