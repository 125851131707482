import React, { useEffect } from 'react'
import { ReactComponent as Logo } from './broadband.svg'
import { Widget } from '../shared/stickee-widget/Widget'
import { WidgetHeader } from '../shared/stickee-widget/WidgetHeader'
import { WidgetFooter } from '../shared/stickee-widget/WidgetFooter'

export const BroadbandQuotes = (props) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://whitelabels.stickeebroadband.co.uk/js/loader.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <WidgetHeader logo={Logo}/>
      <Widget widgetId='youtility-436'/>
      <WidgetFooter/>
    </>
  )
}
