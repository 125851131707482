import styled from 'styled-components'

const StyledAccordionWrapper = styled.div`
border-bottom: 1px solid var(--grey100-color);

&:last-child {
  border-bottom-color: transparent;

}
  button {
    border: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--grey800-color);
    border-radius: 0;
    height: auto;
    padding: 16px 24px 17px 24px;
    margin-top: 0;

    &:hover, &.hover,
    &:focus, &.focus,
    &:active, &.active,
    &:disabled, &.disabled {
      background: var(--white-color) !important;
      border: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--grey800-color);
    }
    &:active {
      .accordionTitle{
        text-decoration: none;
      }
    }
    &:focus {
      .accordionTitle {
        border-bottom: 1px dashed var(--grey500-color);
      }
    }

    .accordionTitle {
      margin-bottom: 0;
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--grey800-color);
      border-bottom: 1px solid transparent;

      span {
        margin-left: 8px;
      }

    }

    p.accordionSubTitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--grey500-color);
      width: 100%;
      text-align: left;
      margin-bottom: 0;

      strong {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-left: 2px;
        color: var(--primary050-color);
      }

    }

    &.pointer {
      cursor: default !important;
    }
    
    .cardBody {
      li {
        &:not(:last-child) {
          border-bottom: 1px dashed var(--grey100-color);
        }
      }
    } 
    
    &:focus {
      padding: 16px 24px 17px 24px;
    }
  }

  .collapseContainer {
    border-top: 1px solid var(--grey100-color);
    .card {
      border: none;
      .card-body {
        padding: 12px 24px 20px 24px;
      }
    }
  }
`
export default StyledAccordionWrapper
