import React from 'react'
import StyledPrimaryButton from './style'
import { Spinner } from 'reactstrap'

const PrimaryButton = (props) => {
  const {
    children,
    loading,
    className
  } = props

  const contents = loading ? <Spinner/> : children

  const classes = (className || [])
    .concat(loading ? ['loading'] : [])

  return (<StyledPrimaryButton
      type='button'
      variant='primary'
      data-testid='submitButton'
      className={classes}
      { ...props }
    >
      { contents }
    </StyledPrimaryButton>
  )
}

export default PrimaryButton
