import styled from 'styled-components'

export const StyledReviewItem = styled.li`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  width: 100%;

  .title {
    color: var(--grey500-color);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  
  .line {
    color: var(--grey300-color);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
  }
  
  a {
    display: inline-block;
    width: 44px;
    height: 44px;
    align-self: center;
    text-align: right;
    position: relative;

    svg {
      position: absolute;
      right: 0%;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &:focus {
      outline: none;
      svg {
        border-bottom: 1px dashed var(--primary500-color);
        border-radius: none;
      }
    }
  }


  .editButtonWrapper {
      &:focus {
      outline: none;
      svg {
        border-bottom: 1px dashed var(--primary500-color);
        border-radius: none;
      }
    }
  }
  button {
    display: inline-block;
    width: 44px;
    height: 44px;
    align-self: center;
    text-align: right;
    position: relative;

    svg {
      position: absolute;
      right: 0%;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
`
