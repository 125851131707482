import { ReactComponent as BulletTick } from '../images/bullet-tick.svg'
import React from 'react'
import PrimaryButton from './components/Button/PrimaryButton'
import { AppLogo, StyledLandingScreen } from './LandingScreen.style'

export const LandingScreen = (props) => {
  const { title, description, bullets, action, actionLabel, isLoading } = props

  return (
    <StyledLandingScreen data-testid={ 'LandingPageBlock' } className="landingPage desktopLayout">
      <main className="innerSection">
        <AppLogo role="img" aria-label="Youtility"></AppLogo>
        <div role="heading" aria-level="1">
          <h1>{ title }</h1>
          <p>{ description }</p>
        </div>
        <div className="bulletList">
          <ul>
            { bullets.map((listData, index) => (
              <li key={ index }><BulletTick className="svgStrokeColor"/><span>{ listData }</span></li>)) }
          </ul>
        </div>
      </main>
      <footer role="contentinfo" className="footer-container">
        <PrimaryButton data-testid={ 'LandingPageSubmitButton' }
                       onClick={ action }
                       loading={ isLoading ? 1 : 0 }>{ actionLabel }</PrimaryButton>
        <div className='footerLink'>
        We use <a href="https://youtility.co.uk/cookie-policy">cookies</a> to enhance your experience, by continuing you agree to their use.
        </div>
      </footer>
    </StyledLandingScreen>

  )
}
