import styled from 'styled-components'

export const StyledWidgetHeader = styled.div`
  padding: 20px 16px;
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: inset 0px -1px 0px #DBE2F3;
  
  @media screen and (max-width: 992px) {
    margin-bottom: 12px;
  }

  svg {
    padding-right: 6px;
  }
`
