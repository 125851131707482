import { createSlice } from '@reduxjs/toolkit'
import { api } from '../api'

const initialState = {}

const energySlice = createSlice({
  name: 'energy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.submitStep.matchFulfilled,
      (state, { payload, meta }) => {
        const step = payload.name
        if (step) {
          console.log('submitStep match fulfilled', step)
          state[step] = payload
        }
      }
    )
    builder.addMatcher(
      api.endpoints.submitStep.matchFulfilled,
      (state, { payload, meta }) => {
        const {
          arg: {
            originalArgs: { name, submissionValues }
          }
        } = meta
        console.log('name', name, 'submissionValues', submissionValues)
        const step = state[name]
        if (step) {
          console.log('submitStep match fulfilled', step)
          const values = step.values
          submissionValues.forEach((submission) => {
            const { stepValueId, value } = submission
            const v = values[stepValueId] || {}
            v.existingValue = value
            console.log('submitStep updating', stepValueId, 'to', value)
          })
        }
      }
    )

    builder.addMatcher(
      api.endpoints.getStep.matchFulfilled,
      (state, { payload }) => {
        const step = payload.name
        if (step) {
          console.log('getStep match fulfilled', step)
          state[step] = payload
          state.workflowId = payload.workflowId
        }
      }
    )
    builder.addMatcher(
      api.endpoints.getStep.matchRejected,
      (state, { payload }) => {
        console.log('getStep.matchRejected', payload)
      }
    )
    builder.addMatcher(
      api.endpoints.submitStep.matchRejected,
      (state, { payload }) => {
        console.log('submitStep matchRejected', payload)
      }
    )
    builder.addMatcher(
      api.endpoints.getWorkflow.matchFulfilled,
      (state, { payload }) => {
        const step = payload.name
        if (step) {
          console.log('getWorkflow match fulfilled', step)
          state[step] = payload
          state.workflowId = payload.workflowId
        }
      }
    )
    builder.addMatcher(
      api.endpoints.createWorkflow.matchFulfilled,
      (state, { payload }) => {
        const step = payload.name
        if (step) {
          console.log('createWorkflow match fulfilled', step)
          state[step] = payload
          state.workflowId = payload.workflowId
        }
      }
    )
  }
})

export const { reducer } = energySlice
