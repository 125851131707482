import styled from 'styled-components'

export const StyledPreSwitchReview = styled.ul`
  padding: 0;
  margin-bottom: 0;
  li {
    &:not(:last-child) {
      border-bottom: 1px dashed var(--grey100-color);
    }
  }

`
export const StyledPreSwitchWrapper = styled.div`
border-top: 1px solid var(--grey100-color);
  .accordionWrapper {
    &:first-child {
      background: red;
    }
  }
  button.accordionBtn {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: var(--primary500-color);
    padding: 21px 24px;
    &:hover,
    &:focus {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: var(--primary500-color);      
    }    
    .accordionIconWrapper {
      display: flex;
      align-items: center;
      width: calc(100% - 16px);

      div {
        border-bottom: 1px solid transparent;
      }
      svg {
        margin-right: 12px;
      }
    }
    &:focus {
      .accordionIconWrapper {
        div {
          border-bottom: 1px dashed;
        }
      }
    }
  }
`
