import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAddressesByPostCode } from '../api/supplyPostcodeAddress.api'

// create slice
const name = 'address'
const initialState = createInitialState()
const extraActions = createExtraActions()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const supplyPostcodeAddressSlice = createSlice({ name, initialState, reducers, extraReducers })

// exports
export const addressesActions = { ...extraActions, ...supplyPostcodeAddressSlice.actions }
export const reducer = supplyPostcodeAddressSlice.reducer

// implementation
function createInitialState () {
  return {
    addresses: {}
  }
}

function createExtraActions() {
  return {
    getAddresses: getAddresses()
  }

  function getAddresses() {
    return createAsyncThunk(
      `${name}/getAddressesByPostcode`,
      async (payload) => {
        const res = await getAddressesByPostCode(payload.postcode)
        // res.data.push({
        //   id: 'SW21BH.2B0D35330B4B190F7DC5789D830F8641',
        //   name: '2, Jelf Road, London'
        // })
        return res?.data || []
      }
    )
  }
}

function createExtraReducers() {
  return {
    ...getAddressesByPostCode()
  }

  function getAddressesByPostCode() {
    const { pending, fulfilled, rejected } = extraActions.getAddresses
    return {
      [pending]: (state, action) => {
        const updatedAddressesKey = action.meta.arg.addressValueId

        const updatedAddresses = {
          ...state.addresses
        }
        updatedAddresses[updatedAddressesKey] = { ...updatedAddresses[updatedAddressesKey], loading: true }

        state.addresses = {
          ...updatedAddresses
        }
      },
      [fulfilled]: (state, action) => {
        const updatedAddressesKey = action.meta.arg.addressValueId

        const updatedAddresses = {
          ...state.addresses
        }
        updatedAddresses[updatedAddressesKey] = { ...updatedAddresses[updatedAddressesKey], data: action.payload?.map(a => ({ id: a.id, label: a.name })) || [] }

        state.addresses = {
          ...updatedAddresses
        }
      },
      [rejected]: (state, action) => {
        state.addresses[action.meta.arg.addressValueId] = { ...state.addresses[action.meta.arg.addressValueId], error: action.error }
      }
    }
  }
}

function createReducers() {
  return { ...addAddressesReducers() }

  function addAddressesReducers() {
    return {
      updateAddresses(state, action) {
        state.addresses[action.payload?.addressValueId] = { ...state.addresses[action.payload?.addressValueId], data: action.payload?.addresses || [] }
      },
      setPostcode(state, action) {
        state.addresses[action.payload?.addressValueId] = { ...state.addresses[action.payload?.addressValueId], postcode: action.payload?.postcode || [] }
      }
    }
  }
}
