import _ from 'lodash'
import { defaultConfigFor } from './defaults/config'
import { clientConfigFor, clientThemeFor } from './clients/config'

const context = {
  client: 'www',
  environment: process.env.REACT_APP_HOST_ENV
}

const defaultConfig = defaultConfigFor(context.client, context.environment)
const clientConfig = clientConfigFor(context.client, context.environment)

export const config = _.merge({}, defaultConfig, clientConfig)
if (config.debug) {
  console.log('Config is ', config)
}

export const clientTheme = clientThemeFor(context.client)
