import React, { useEffect } from 'react'
import { Route, Redirect, Switch, useLocation } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Layout from './shared/components/Layout'
import { GlobalStyle } from './global-styles'
import { routes as broadbandRoutes } from './broadband/BroadbandRoutes'
import { routes as mobileRoutes } from './mobile/MobileRoutes'
import ReactDOM from 'react-dom'
import { config } from './config/config'
import _ from 'lodash'
import ReactGA from 'react-ga'

const { analytics } = config
const { gaTrackingId } = analytics

if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react')
  axe(React, ReactDOM, 1000)
}

const App = () => {
  ReactGA.initialize(gaTrackingId)
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const location = useLocation()

  const isMobileSafari = () => {
    const isIos = /iP(ad|od|hone)/i.test(window.navigator.userAgent)
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)
    return isIos && isSafari
  }

  useEffect(() => {
    if (isMobileSafari()) {
      document.documentElement.style.setProperty('--height', `${window.innerHeight}px`)
    }
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    const listener = (e) => {
      document.activeElement.blur()
    }
    document.addEventListener('touchmove', listener)
    return () => document.removeEventListener('touchmove', listener)
  }, [])

  const verticals = [
    broadbandRoutes,
    mobileRoutes
  ]

  const routes = _.flattenDeep(verticals)

  return (
    <div className='main-container'>
      <Layout>
        <Switch>
          <Route exact path='/'>
            <Redirect to={routes[0].path}/>
          </Route>
          { routes.map((route, i) => (
            <Route
              exact
              key={ i }
              path={ route.path }
              render={ (props) => <route.component { ...props } config={ route.config || {} }/> }
            />
          )) }
          <Route path='*'>
            <Redirect to='/'/>
          </Route>
        </Switch>
      </Layout>
      <GlobalStyle/>
    </div>
  )
}

export default withTheme(App)
