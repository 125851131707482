import styled from 'styled-components'
import StyledButtonDefaults from '../StyledButtonDefaults'

const StyledPrimaryButton = styled(StyledButtonDefaults)`
  color: var(--white-color);
  background: var(--primary500-color);

  &:hover, &.hover {
    background: var(--primary500-color);
    border: 2px solid var(--white-color);
  }
  
  &:focus, &.focus {
    background: var(--primary500-color);
    border: 2px solid var(--white-color);
  }
  
  &:active, &.active {
    background: var(--primary500-color) !important;
    border-color: var(--primary500-color) !important;
    filter: brightness(0.8);
    color: var(--white-color);
  }
  
  &:disabled, &.disabled {
    color: var(--white-color);
    background: var(--grey100-color);
    border-color: var(--grey100-color);
  }
  
  &.loading:disabled, &.loading.disabled {
    background: var(--primary500-color);
    border-color: var(--primary500-color);
    opacity: 1;
  }

  &.small {
    width: auto;
    padding: 0 20px;

    &:hover,  &.hover, &.disabled {
      padding: 0 20px;
    }

    &.loading:disabled, &.loading.disabled {
      background: var(--primary500-color);
      border-color: var(--primary500-color);
      opacity: 1;
    }

  } 
    
}

  
`

export default StyledPrimaryButton
